
import { Injectable, } from '@angular/core';
import { Resolve } from '@angular/router';

import { APIService } from '../API.service';

@Injectable()
export class TicketActionsResolver implements Resolve<any> {
  constructor(
    private api: APIService
  ) {}

  resolve(
  ) {
    return this.api.ListTicketActions();
  }
}
